import React, { useEffect, useState } from 'react'
import { useUI } from '@components/ui/context'
import cn from 'classnames'
import styles from './index.module.css'
import { LoadingDots } from '@components/ui'
import { useRouter } from 'next/router'
import { parse } from 'query-string'

const Firework = ({ data, s: gs = {} }) => {
  const router = useRouter()
  const { fireworkReady } = useUI()
  const s = { ...styles, ...gs }
  const mode = data?.mode || 'Carousel'
  const [isHidden, setIsHidden] = useState(false)
  const [banFirework, setBanFirework] = useState(false)

  useEffect(() => {
    const isHiddenValue = data.endTime
      ? new Date() <= new Date(data.endTime) &&
        new Date() >= new Date(data?.startTime)
      : true
    setIsHidden(isHiddenValue)
  }, [data.endTime, data.startTime])

  useEffect(() => {
    if (mode == 'liveStream' && data?.url) {
      document.addEventListener('fw:player:transition', handlePlayerTransition)
      return () => {
        document.removeEventListener(
          'fw:player:transition',
          handlePlayerTransition
        )
      }
    }
  }, [])

  const handlePlayerTransition = (event) => {
    event.stopPropagation()
    // window._fwn.player.close()
    // window._fwn.player.minimize()
    if (event.detail.transitionTo === 'fullscreen') {
      router.push(data?.url)
    }
  }

  useEffect(() => {
    const query = parse(window.location.search)
    if (mode === 'liveStream' && query?.source !== 'anker') {
      fetch('/geolocation').then(async (res) => {
        const { geo } = await res.json()
        if (geo?.country?.code && geo.country.code.toLowerCase() === 'cn') {
          setBanFirework(true)
        }
      })
    }
  }, [mode])

  switch (mode) {
    case 'Carousel':
      return (
        <div className={cn(s.Firework, 'layer')}>
          <div className={cn(s.content, 'content')}>
            {data.title && (
              <h2
                className={s.title}
                dangerouslySetInnerHTML={{ __html: data.title }}
              ></h2>
            )}
            {fireworkReady ? (
              <div className={s.FireworkBox}>
                <fw-embed-feed
                  channel="anker"
                  playlist={data?.playlist}
                  mode="row"
                  open_in="default"
                  max_videos="0"
                  placement="middle"
                  branding="false"
                  player_placement={data?.playerPlacement || 'bottom-left'}
                ></fw-embed-feed>
              </div>
            ) : (
              <div className={cn(s.FireworkBox, s.LoadingDots)}>
                <LoadingDots />
              </div>
            )}
          </div>
        </div>
      )
    case 'FloatingPlayer':
      return (
        <fw-storyblock
          channel={data?.channel || 'anker'}
          playlist={data?.playlist}
          mode="pinned"
          autoplay="true"
          player_placement={data?.playerPlacement || 'bottom-left'}
        ></fw-storyblock>
      )
    case 'liveStream':
      return (
        <>
          {data?.playerMargin && (
            <style>
              {`
                :root{
                    --fw-player-margin: ${data?.playerMargin};
                  }
                  @media(max-width: 769px) {
                    ${
                      data?.mobPlayerMargin &&
                      `:root{
                      --fw-player-margin: ${data?.mobPlayerMargin};
                    }`
                    }
                  }
                `}
            </style>
          )}
          {isHidden && !banFirework && (
            <fw-storyblock
              channel={data?.channel || 'anker'}
              playlist={data?.playlist}
              autoplay="true"
              mode="pinned"
              player_placement={data?.playerPlacement || 'bottom-left'}
            ></fw-storyblock>
          )}
        </>
      )
    // case 'StoryBlock':
    //   return (
    //     <fw-storyblock
    //       channel="anker"
    //       playlist={data?.playlist}
    //       autoplay="true"
    //     ></fw-storyblock>
    //   )
    // case 'Grid':
    //   return (
    //     <div className={cn(s.Firework, 'layer')}>
    //       <div className={cn(s.content, 'content')}>
    //         {data.title && (
    //           <h2
    //             className={s.title}
    //             dangerouslySetInnerHTML={{ __html: data.title }}
    //           ></h2>
    //         )}
    //         {fireworkReady ? (
    //           <div className={s.FireworkBox}>
    //             <fw-embed-feed
    //               channel="anker"
    //               playlist={data?.playlist}
    //               mode="grid"
    //               open_in="default"
    //               max_videos="0"
    //               placement="middle"
    //               player_placement="bottom-right"
    //             ></fw-embed-feed>
    //           </div>
    //         ) : (
    //           <div className={cn(s.FireworkBox, s.LoadingDots)}>
    //             <LoadingDots />
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   )
    // case 'HeroUnit':
    //   return (
    //     <fw-herounit
    //       channel="anker"
    //       playlist={data?.playlist}
    //     ></fw-herounit>
    //   )
  }
}

export default Firework
